import PasswordValidator from 'password-validator';
import queryString from 'query-string';
import axios from 'axios';
import { isThisMonth, setMonth } from 'date-fns';
import format from 'date-fns/format';
import Cookies from 'js-cookie';
import { createFilter } from 'react-select';

export function getOptions(optionList: any[]) {
  return optionList.map((option: any) =>
    <option value={ option } key={ option }>{ option }</option>,
  );
}

export function getOptionObject(value: string) {
  return value && { value, label: value };
}


export function toTitleCase(str: string) {
  return str.replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
}

export function getSlug(title: string) {
  let slug = title.replace(/^\s+|\s+$/g, ''); // trim
  slug = slug.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;';
  const to = 'AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------';
  for (let i = 0, l = from.length; i < l; i++) {
    slug = slug.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  slug = slug.replace(/[^a-z0-9 -]/g, ''); // remove invalid chars
  slug = slug.replace(/\s+/g, '-'); // collapse whitespace and replace by -
  slug = slug.replace(/-+/g, '-'); // collapse dashes

  return slug;
}

export const tvrOptions = ['TV-MA', 'TV-14', 'TV-PG', 'TV-G', 'TV-7', 'TV-Y'].map(i => ({ value: i, label: i }));

export function isCurrentMonth(date: Date | string) {
  return date && isThisMonth(new Date(date));
}

export function getFirstDayOfNextMonth() {
  const date = new Date();

  return new Date(date.getFullYear(), date.getMonth() + 1, 1);
}

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const getMonthName = (monthNumber: number) => {
  return format(setMonth(new Date(), monthNumber), 'MMMM');
};
export const getCurrentQuarter = (currentMonth: number, monthsByQuarter: number) => {
  const quarter = Math.ceil(currentMonth / monthsByQuarter);
  return { value: `Q${ quarter }`, label: `Quarter ${ quarter }` };
};
export const getLastQuarter = (currentMonth: number, monthsByQuarter: number) => {
  let quarter = Math.ceil(currentMonth / monthsByQuarter) - 1;
  if (quarter < 1) quarter = 1;
  return { value: `Q${ quarter }`, label: `Quarter ${ quarter }` };
};

export const validatePassword = (password: string) => {
  const schema = new PasswordValidator();
  schema
    .is().min(8)
    .has().letters()
    .has().not().spaces();

  return schema.validate(password);
};

export const toTimeString = (totalSeconds) => {
  const totalMs = totalSeconds * 1000;
  return new Date(totalMs).toISOString().slice(11, 19);
};

export const jsonToFormData = (jsonObject: Object): FormData => {
  let formData = new FormData();

  Object.keys(jsonObject).forEach((key) => {
    formData.append(key, jsonObject[key]);
  });
  return formData;
};

export const selectFilterFromStart = () => {
  return createFilter({
    matchFrom: 'start',
  });
};

export const calculateAssetsSize = (...args: File[]) => {
  // const args = arguments;
  let totalSize = args.reduce((previousValue: number, currentValue: File) => {
    if (currentValue) {
      previousValue += currentValue.size;
    }
    return previousValue;
  }, 0);

  return totalSize;
};

export const getSelectedMonthOpt = (ini: any, end: any) => {
  let selectedMonth = null;  

  if (ini !== undefined) {
    const iniDate = new Date(ini as string);
    const endDate = new Date(end as string);
    const daysDiff = (endDate.getTime() - iniDate.getTime()) / (1000 * 60 * 60 * 24);
    const year = endDate.getFullYear();
    if (daysDiff > 31) {
      const quarter = Math.floor(endDate.getMonth() / 3 + 1);
      selectedMonth = { value: `Q${ quarter }`, label: `Quarter ${ quarter }` };
    } else {
      const month = endDate.getMonth()
      selectedMonth = {
        value: month,
        label: getMonthName(month),
      };
    }
  }

  return selectedMonth;
};

export const getSelectedYearOpt = (ini: any, end: any) => {
  let selectedYear = null;  

  if (ini !== undefined) {
    const endDate = new Date(end as string);
    const year = endDate.getFullYear();
    selectedYear = {value: Number(year), label: Number(year)};
  }

  return selectedYear;
};
