import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import ErrorMessages from '@components/ErrorMessages';
import { Tooltip } from './tooltip/Tooltip';

type InputTextType = {
  id?: string,
  name: string,
  value?: string,
  inputClassName?: string,
  labelText: string,
  placeholder?: string,
  type: string,
  disabled?: boolean,
  invalid?: boolean,
  onChange?: any,
  onBlur?: any
  tooltipInfo?: string
};
const InputText = forwardRef(({
  id,
  name,
  value,
  inputClassName,
  labelText,
  placeholder,
  type,
  disabled = false,
  invalid = false,
  onChange,
  onBlur,
  tooltipInfo,
}: InputTextType, inputRef: any) => {

  const { getFieldState } = useFormContext();
  const fieldState = getFieldState(name);

  return (
    <div className={ `mb-3 ${ disabled ? 'opacity-50' : '' }` }>
      <div className="col items-center">
        <label htmlFor={ id } className="form-label">{ labelText }</label>
        { tooltipInfo ? <Tooltip title={ tooltipInfo } arrow placement="right"/> : null }
      </div>
      <input
        ref={ inputRef }
        id={ id }
        name={ name }
        type={ type }
        aria-invalid={ fieldState.invalid }
        className={
          classNames('form-field',
            inputClassName,
            { 'form-field-invalid': fieldState.invalid },
          )
        }
        disabled={ disabled }
        onChange={ onChange }
        onBlur={ onBlur }
        value={ value }
        placeholder={ placeholder }
      />
      <ErrorMessages error={ fieldState.error }></ErrorMessages>
    </div>
  );

});
InputText.displayName = 'InputText';
export default InputText;
