import { FC } from "react";
import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps,  tooltipClasses, styled } from "@mui/material";
import type { TooltipProps } from "./Tooltip.types";

const DarkToolTip = styled(({ className, ...props }: MuiTooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} className="mx-2" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: 'white',
    boxShadow: "-14px 13px 60px 0px #000000",
    fontSize: 12,
    fontWeight: 700,
    padding: 20,
    borderWidth: 2,
    borderColor: "white",
    borderStyle: "solid",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
}));

export const Tooltip: FC<TooltipProps> = ({children, title, arrow, placement, useChildren}) => {
  return (
    <DarkToolTip title={title} arrow={arrow} placement={placement}>
      {
        useChildren ? children : <i className="fa-solid fa-circle-info"></i>
      }
    </DarkToolTip>
  )
}
