import React, { forwardRef, useEffect } from 'react';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

const ErrorMessages = ({ error }: { error: { type?: string, message?: string, ref?: any } }) => {
  const getMessage = (error) => {
    let message;
    let customMessage = error.message;

    switch (error.type) {
      case 'required':
        message = 'This field is required.';
        break;
      case 'maxLength':
        message = 'Max length of characters reached.';
        break;
      default:
        message = 'Validation error.';
        break;
    }

    return <li>
      <span role={ 'alert' }>
        { customMessage || message }
      </span>
    </li>;
  };

  return (
    error ?
      <ul className={ 'text-sm text-right text-warning text-small' }>
        { getMessage(error) }
      </ul> : <></>
  );
};
export default ErrorMessages;
