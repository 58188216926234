import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import { ContributorForm } from '@components/forms/ContributorForm';
import Cookies from 'js-cookie';
import { useUser } from 'hooks/useUser';

export default function ContributorDropdown({ contributorId, onChange, loading, disabled, selectClassName = 'react-select flex-1', full = true }: any) {
  const user = useUser();
  const [showSpinner, setShowSpinner] = useState(loading);
  const [selectedOption, setSelectedOption] = useState<{ id: String, contributor: string }>();
  const [contributors, setContributors] = useState([]);
  const [showContributorForm, setShowContributorForm] = useState(false);

  useEffect(() => {
    setShowSpinner(loading);
  }, [loading]);

  useEffect(() => {
    if (contributorId) {
      const selectedContributor = contributors.find(c => c.id === contributorId);
      setSelectedOption(selectedContributor);
      onChange(selectedContributor);
    }
  }, [contributorId, contributors]);

  useEffect(() => {
    fetchContributors();
  }, [user]);

  const fetchContributors = async () => {
    try {
      setShowSpinner(true);

      const API_AUTH_URL = (process.env.NEXT_PUBLIC_API_AUTH_URL as string);
      const response = await axios(API_AUTH_URL + '/auth/contributor-user', {
        headers: {
          'Authorization': `Bearer ${ Cookies.get('access') }`,
        },
      })
        .then(r => r.data);
      if (response.result === 'success') {
        setContributors(full ? response.data : [].concat([{"id": "all", "contributor": "All contributors"}], response.data));
        if (!full) {
          setSelectedOption({"id": "all", "contributor": "All contributors"});
        }
      }

      setShowSpinner(false);
    } catch (e) {
      setShowSpinner(false);
    }
  };
  
  const onChangeHandler = async (selectedOption: any) => {
    setSelectedOption(selectedOption);
    if (onChange) {
      onChange(selectedOption);
    }
  };

  const onNewContributor = async (newContributor) => {
    await fetchContributors();
    setSelectedOption(newContributor);
    if (onChange) {
      onChange(newContributor);
    }
    setShowContributorForm(false);
  };


  return (
    <>
      <div className={full ? "mb-3" : ""}>
        { full && (
          <label htmlFor="contributorSelect" className="form-label">Contributor:</label>
        )}
        <div className={ 'flex items-center gap-1' }>
          <Select
            isDisabled={ disabled }
            isSearchable={ true }
            inputId={ 'contributorSelect' }
            value={ selectedOption }
            options={ contributors }
            placeholder={ full ? 'Select contributor' : 'Contributor' }
            className={ selectClassName }
            classNamePrefix="react-select"
            onChange={ onChangeHandler }
            isLoading={ showSpinner }
            getOptionLabel={ (option: any) => option.contributor }
            getOptionValue={ (option: any) => option.id }
          />
          { full && (
            <button className={ 'btn btn-yellow' }
                    title={ 'Add Contributor' }
                    type={ 'button' }
                    disabled={ disabled }
                    onClick={ () => setShowContributorForm(true) }
            >
              <i className="fa-solid fa-plus"></i>
            </button>
          )}
        </div>
      </div>

      <Modal
        open={ showContributorForm }
        onClose={ () => setShowContributorForm(false) }
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className={ 'flex justify-center items-center text-black' }
      >
        <div className={ 'rounded bg-white px-4 pb-4 pt-8 relative w-96 min-w-fit' }>
          <a className={ 'p-2 absolute right-2 top-1 cursor-pointer' } onClick={ () => setShowContributorForm(false) }>
            <i className={ 'fa-solid fa-xmark' }></i>
          </a>
          <div className={ 'mb-4' }>
            <h2 className={ 'text-xl font-bold text-center' }>Add Contributor</h2>
          </div>
          <ContributorForm
            onSubmit={ onNewContributor }
            onCancel={ () => setShowContributorForm(false) }
          />
        </div>
      </Modal>

    </>
  );
}
